MyLottery<template>
  <div class="wrapper">
    <section class="condition">
      <!-- 中獎條件 -->
      <div class="title">{{ rules.WinningCriteria }}</div>
      <div class="notice">{{ rules.title1_h2 }}</div>
      <div class="content">
        <div class="des">{{rules.content1}}</div>
        <br />
        <div class="des dot">
          {{rules.conditionA}}
        </div>
        <div class="des dot">
         {{rules.conditionB}}
        </div>
        <br />
        <div class="des">
        {{rules.conditionC}}
        </div>
      </div>
      <!-- 實例 -->
      <div class="example">
        <div class="num-wrap">
          <div class="item">2</div>
          <div class="item">6</div>
          <div class="item">8</div>
          <div class="item">5</div>
          <div class="item">7</div>
          <div class="item">9</div>
        </div>
        <div class="a-group">
          <div class="columname">A</div>
          <div class="row">
            <div class="icons">
              <div class="icon-box" v-for="item in 3" :key="`icon1_${item}`">
                <svg class="icon" aria-hidden="true">
                  <use xlink:href="#icon-right"></use>
                </svg>
              </div>
              <div class="icon-box">
                <svg class="icon" aria-hidden="true">
                  <use xlink:href="#icon-error1"></use>
                </svg>
              </div>
              <div class="icon-box" v-for="item in 2" :key="`icon2_${item}`">
                <svg class="icon" aria-hidden="true">
                  <use xlink:href="#icon-right"></use>
                </svg>
              </div>
            </div>
            <div class="icons">
              <div class="cricle">
                <div
                  class="icon-box"
                  v-for="item in ['2', '6', '8']"
                  :key="item"
                >
                  {{ item }}
                </div>
              </div>
              <div
                class="icon-box primary"
                v-for="item in ['8', '7', '9']"
                :key="item"
              >
                {{ item }}
              </div>
            </div>
          </div>
        </div>
        <div class="a-group">
          <div class="columname">B</div>
          <div class="row">
            <div class="icons">
              <div class="icon-box">
                <svg class="icon" aria-hidden="true">
                  <use xlink:href="#icon-error1"></use>
                </svg>
              </div>
              <div class="icon-box" v-for="item in 5" :key="`icon2_${item}`">
                <svg class="icon" aria-hidden="true">
                  <use xlink:href="#icon-right"></use>
                </svg>
              </div>
            </div>
            <div class="icons primary">
              <div
                class="icon-box primary"
                v-for="(item, index) in ['5', '6', '8', '5', '7', '9']"
                :key="item + index"
              >
                {{ item }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- 開獎規則 -->
    <section class="condition">
      <div class="title">{{ rules.LotteryRules }}</div>
      <div class="notice">{{ rules.title2_h2 }}</div>
      <div class="content des">{{ rules.content2 }}</div>
    </section>
    <!-- 奖金来源及分配 -->
    <section class="condition">
      <div class="title">{{ rules.prizeFund }}</div>
      <div class="notice">{{ rules.title3_h2 }}</div>
      <div class="content1">
        <div class="des">{{ rules.source1}}</div>
        <div class="des">{{ rules.source2}}</div>
        <div class="des">{{ rules.source3}}</div>
      </div>
      <!-- 分配 -->
      <div class="notice">{{ rules.PrizeDis }}</div>
      <!-- 分配图 -->
      <div class="example example-dis">
        <div class="pie-img">
          <img src="@/static/img/lottery/lotteryPie.png" />
        </div>
        <div class="example-title">
          <div class="lgText">命中数字</div>
          <div class="rgText">奖池分配</div>
        </div>
        <div class="match-list">
          <div class="match-item" v-for="item in matchList" :key="item.id">
            <div class="match-number">
              <div class="cricle" :style="'background:' + item.color"></div>
              <div class="num" v-if="item.id === 7">{{rules.DaoFund}}</div>
              <div class="num" v-else v-html="$t('lottery.history.MatchFirst', { number: item.number })">{{$t('lottery.history.MatchFirst', { number: item.number })}}</div>
            </div>
            <div class="prize-point">{{item.point}}%</div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'LotteryRule',
  components: {},
  data () {
    return {
      matchList: [
        {
          id: 1,
          number: 1,
          point: 10,
          color: '#38F2F8'
        }, {
          id: 2,
          number: 2,
          point: 10,
          color: '#60D937'
        }, {
          id: 3,
          number: 3,
          point: 10,
          color: '#929292'
        }, {
          id: 4,
          number: 4,
          point: 10,
          color: '#F8BA2B'
        }, {
          id: 5,
          number: 5,
          point: 20,
          color: '#FD251C'
        }, {
          id: 6,
          number: 6,
          point: 30,
          color: '#A883FC'
        }, {
          id: 7,
          number: 'DAO生态基金',
          point: 10,
          color: '#2DB2ED'
        }
      ]
    }
  },
  computed: {
    rules () {
      return this.$t('lottery.rules')
    }
  }
}
</script>

<style
SvgIcon lang="scss" scoped>
.wrapper {
  padding: 43px 36px;
  box-sizing: border-box;
  .condition {
    margin-bottom: 30px;
    .title {
      color: #a85ffc;
      font-weight: bold;
      font-size: 24px;
      margin-bottom: 20px;
    }
    .notice {
      font-size: 14px;
      font-weight: bold;
      color: #4c5968;
      margin-bottom: 20px;
    }
    .des {
      font-size: 12px;
      color: #4c5968;
      line-height: 20px;
      &.dot {
        &::before{
          content: "•";
          margin-right: 4px;
        }
      }
    }
    .example {
      max-width: 302px;
      margin: auto;
      margin-top: 30px;
      min-height: 192px;
      background: rgba(255, 255, 255, 0.39);
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
      border-radius: 28px;
      padding: 23px 27px 27px 0;
      box-sizing: border-box;
      .num-wrap {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 10px 0 48px;
        box-sizing: border-box;
        .item {
          width: 30px;
          height: 30px;
          border-radius: 50%;
          line-height: 30px;
          text-align: center;
          color: #fff;
          font-weight: bold;
          text-shadow: 0px 3px 6px rgba(0, 0, 0, 0.25);
        }
        .item:nth-child(1) {
          background-color: #a55df9;
        }
        .item:nth-child(2) {
          background-color: #29abe5;
        }
        .item:nth-child(3) {
          background-color: #23ddad;
        }
        .item:nth-child(4) {
          background-color: #e63d3d;
        }
        .item:nth-child(5) {
          background-color: #f7ad24;
        }
        .item:nth-child(6) {
          background-color: #da2fd3;
        }
      }
      .a-group {
        display: flex;
        margin-bottom: 5px;
        .columname {
          width: 56px;
          text-align: center;
          font-size: 16px;
          font-weight: bold;
          line-height: 19px;
          color: #2a2b33;
          margin-top: 35px;
        }
        .row {
          width: 100%;
          .icons {
            width: 96%;
            display: flex;
            justify-content: space-between;
            &.primary {
              background: rgba(249, 249, 249, 0.39);
              border: 1px solid #e3e3e3;
              border-radius: 15px;
            }
            .icon-box {
              width: 30px;
              height: 30px;
              line-height: 30px;
              text-align: center;
              font-size: 18px;
              font-weight: bold;
              color: #2a2b33;
              &.primary {
                color: #c1c1c1;
              }
            }
            .icon {
              flex: 1;
              width: 12px;
              height: 12px;
            }
            .cricle {
              display: flex;
              width: 45%;
              justify-content: space-between;
              height: 30px;
              line-height: 30px;
              border: 1px solid #a85ffc;
              border-radius: 15px;
            }
          }
        }
      }
    }
    .content1 {
      margin-top: -18px;
      margin-bottom: 20px;
    }
    .example-dis {
      min-height: 330px;
      padding: 30px 25px;
      box-sizing: border-box;
      .pie-img {
        width: 88px;
        height: 88px;
        margin: auto;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .example-title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 10px;
        font-weight: bold;
        color: #a85ffc;
        margin-top: 6px;
        margin-bottom: 10px;
      }
      .match-list {
        font-size: 12px;
        color: #4C5968;
        .match-item {
          display: flex;
          justify-content: space-between;
          margin-bottom: 5px;
          .match-number {
            display: flex;
            align-items: center;
          }
          .cricle {
            width: 15px;
            height: 15px;
            border-radius: 50%;
            margin-right: 10px;
          }
          .prize-point {
            font-weight: bold;
          }
        }
      }
    }
  }
}
</style>
